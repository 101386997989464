@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap');
body,
html {
  margin: 0 !important;
  padding: 0;
  font-style: normal;
  overflow-x: hidden;
}

body {
  width: 100vw;
  overscroll-behavior-y: none;
  position: relative;
}

.stack {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.timeline {
  width: 100vw;
  height: 55vw;
}

.pop {
  font-size: 26px;
}

.subheader {
  padding-top: 50px;
  font-size: 2.8vw;
  font-weight: 500;
  width: 60vw;
}
.skills-text {
  font-size: 2vw;
  padding-top: 40px;
  padding-left: 10vw;
  width: 40vw;
  bottom: 10vw;
}

h2 {
  font-size: 7vw;
  font-weight: 200;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  margin-top: 100px;
  width: 100vw;
  line-height: 2em;
}

.d {
  display: block;
}

.m {
  display: none;
}

@media screen and (max-width: 900px) {
  .stack {
    flex-direction: column;
  }
  .skills-text {
    font-size: 2.3vw;
    padding-left: 5vw;
    width: 55vw;
    bottom: 5vw;
  }

  .timeline {
    margin-left: -45px;
    height: 55vw;
  }
}

.project-text {
  font-size: 1.2vw;
}

@media screen and (max-width: 1200px) {
  .project-text {
    font-size: 1.6vw;
  }
  .pop {
    font-size: 24px;
  }
}

@media screen and (max-width: 700px) {
  .project-text {
    font-size: 1.8vw;
  }
  .pop {
    font-size: 20px;
  }
  .subheader {
    padding-top: 0px;
    font-size: 4vw;
    font-weight: 700;
    width: 70vw;
  }
  .timeline {
    display: none;
  }

  h2 {
    font-size: 55vw;
    font-weight: 600;
    margin-top: 10px;
    line-height: 1em;
  }

  h2.projects {
    font-size: 37vw;
  }
  .d {
    display: none;
  }
  .m {
    display: block;
  }
}

.glass {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

/* section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-snap-align: center;
  perspective: 500px;
}

section > div {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

section > div > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
} */
a {
  text-decoration: none;
}

.decimal {
  font-family: 'Decimal A', 'Decimal B';
  font-style: normal;
}

.landmark {
  font-family: 'Landmark Inline A', 'Landmark Inline B';
  font-style: normal;
}

.archer {
  font-family: 'Archer A', 'Archer B';
  font-style: normal;
}

.whitney {
  font-family: 'Whitney A', 'Whitney B';
  font-style: normal;
  font-weight: 400;
}

.tungsten {
  font-family: 'Tungsten A', 'Tungsten B';
  font-style: normal;
}

::-webkit-scrollbar {
  width: 12px;
  cursor: pointer;
  display: none;
}

::-webkit-scrollbar-track {
  background-color: #fff6ed;
  border-radius: 2px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #343e4c;
  border-radius: 6px;
  cursor: default !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #6c7c96;
}

::selection {
  background: #c3cad5;
}
